import { message } from 'antd'
import { BGM, EXTERNAL_DISTRIBUTOR, SALE_SUPERVISOR } from 'common/config/acl'
import { useAppContext } from 'contexts/AppContext'
import { IUser } from 'interfaces/user'
import { IWarehouseProvinces, Warehouse } from 'interfaces/warehouse'
import { flatMap, get } from 'lodash'
import { useSession } from 'next-auth/react'
import { stringify } from 'qs'
import { useEffect, useMemo, useState } from 'react'
import axiosInstance from 'utils/axiosInstance'
import { defaultStringifyOption, getResponseData } from 'utils/commonUtils'

import useACL from './useACL'

const useGetWarehouseID = () => {
  const [warehouseIDs, setWarehouseIDs] = useState<string[]>()
  const [warehouses, setWarehouses] = useState<Warehouse[]>()
  const [warehouseLocations, setWarehouseLocations] =
    useState<IWarehouseProvinces[]>()
  const [isFinish, setIsFinish] = useState<boolean>(false)
  const { userRoles } = useACL()
  const {
    state: { distributor },
  } = useAppContext()

  const { data: session } = useSession()

  const isRoleBGM = useMemo(() => {
    return userRoles.includes(BGM)
  }, [userRoles])
  const isRoleDistributor = useMemo(() => {
    return userRoles.includes(EXTERNAL_DISTRIBUTOR)
  }, [userRoles])
  const isRoleSaleSupervisor = useMemo(() => {
    return userRoles.includes(SALE_SUPERVISOR)
  }, [userRoles])

  useEffect(() => {
    const getWarehouseID = async () => {
      try {
        let IDs
        //Get ID warehouse managed by BGM
        if (isRoleBGM) {
          const res = await axiosInstance.get(`/v1/users/me`)
          const data = getResponseData<IUser>(res)
          IDs = data?.userWarehouse?.map((ite) => ite?.warehouseID)
        }
        //Get ID warehouse of Distributor
        if (isRoleDistributor) {
          const params = stringify(
            { distributorID: distributor?.id, includeWarehouseProvinces: true },
            defaultStringifyOption
          )
          const res = await axiosInstance.get(`/v1/warehouses?${params}`)
          const data = getResponseData<Warehouse[]>(res)
          IDs = data?.map((ite) => ite?.id)
          const locations = flatMap(data, (item) => item.warehouseProvinces)
          setWarehouses(data)
          setWarehouseLocations(locations)
        }
        if (isRoleSaleSupervisor) {
          const params = stringify(
            { saleSupervisorIDs: [get(session, 'user.id') as string] },
            defaultStringifyOption
          )
          const res = await axiosInstance.get(`/v1/warehouses?${params}`)
          const data = getResponseData<Warehouse[]>(res)
          IDs = data?.map((ite) => ite?.id)
          setWarehouses(data)
        }
        setWarehouseIDs(IDs)
      } catch (error) {
        message?.error(error)
      } finally {
        setIsFinish(true)
      }
    }
    if (
      isRoleBGM ||
      (isRoleDistributor && distributor?.id) ||
      isRoleSaleSupervisor
    ) {
      getWarehouseID()
    } else {
      setIsFinish(true)
    }
  }, [userRoles, distributor?.id, isRoleSaleSupervisor])

  return {
    warehouseIDs,
    warehouses,
    isFinish,
    distributor,
    warehouseLocations,
  }
}

export default useGetWarehouseID
