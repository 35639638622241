import { Cascader, Select, Tooltip } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import axios from 'axios'
import useGetLocations from 'hooks/useGetLocations'
import { DEFAULT_LOCATION_PER_PAGE, DEFAULT_PAGE } from 'hooks/usePaging'
import { isArray } from 'lodash'
import getConfig from 'next/config'
import { stringify } from 'qs'
import React, { useEffect, useMemo, useState } from 'react'
import {
  defaultStringifyOption,
  getResponseData,
  isIndia,
} from 'utils/commonUtils'

interface IProps {
  value?: any
  onChange?: (value, selectedOptions) => void
  multiple?: boolean
  placeholder?: string
  toLevel?: number
  fromLevel?: number
  keyValue?: string
  showAreaName?: boolean
  customDataSelect?: any
  disabled?: boolean
  isRenderSingle?: boolean
  dataLocations?: any
  isGetDataHook?: boolean
  size?: SizeType
  maxTagCount?: number | 'responsive'
}
interface IDistrict {
  id: string
  name: string
}

export interface ILocationCascader {
  halfCheckable: boolean
  id: number
  label: string
  value: number
  children: ILocationCascader[]
}

const {
  publicRuntimeConfig: { adminPortalUrl },
} = getConfig()

const mapData = (data, fromLevel, toLevel, keyValue) => {
  return data
    ?.sort((a, b) => a.name.localeCompare(b.name))
    ?.map((item) => ({
      //parse string for state India
      id: item.locationId,
      value: item[keyValue],
      label: item.name,
      halfCheckable: false,
      children:
        fromLevel < toLevel
          ? mapData(item.children, fromLevel + 1, toLevel, keyValue)
          : null,
    }))
}
const { SHOW_CHILD } = Cascader
const LocationSelect: React.FC<IProps> = ({
  value,
  onChange,
  multiple = false,
  placeholder = 'Select address...',
  toLevel = isIndia ? 5 : 4,
  fromLevel = 2,
  keyValue = 'locationId',
  showAreaName,
  customDataSelect = null,
  disabled = false,
  isRenderSingle = false,
  dataLocations = null,
  isGetDataHook = true,
  size = 'middle',
  maxTagCount,
}) => {
  const [loading, setLoading] = useState(false)
  const [nextDataLocations, setNextDataLocations] = useState([])

  const isOneLevel = useMemo(() => fromLevel === toLevel, [fromLevel, toLevel])

  const { locations, isFinish } =
    !isOneLevel && isGetDataHook && !customDataSelect && useGetLocations()

  useEffect(() => {
    const getLocations = async () => {
      try {
        setLoading(true)
        const params = stringify(
          {
            ignorePerPage: true,
            page: DEFAULT_PAGE,
            perPage: DEFAULT_LOCATION_PER_PAGE,
            level: fromLevel,
          },
          defaultStringifyOption
        )
        const response = await axios.get(
          `${adminPortalUrl}/v1/location-info?${params}`
        )
        const data = getResponseData<IDistrict[]>(response)
        setNextDataLocations(data?.sort((a, b) => a.name.localeCompare(b.name)))
      } catch (error) {
        setNextDataLocations([])
      } finally {
        setLoading(false)
      }
    }

    if (isOneLevel) getLocations()
  }, [isOneLevel])

  useEffect(() => {
    setLoading(true)
    if (isFinish && isGetDataHook && locations?.length > 0) {
      if (isIndia && fromLevel !== 2) {
        const nextData = locations?.reduce((prev, curr) => {
          return isArray(curr?.children) && curr?.children?.length > 0
            ? [...prev, ...curr?.children]
            : prev
        }, [])

        const locationsTree = mapData(nextData, fromLevel, toLevel, keyValue)
        setLoading(false)
        return setNextDataLocations(locationsTree)
      }
      const locationsTree = mapData(locations, fromLevel, toLevel, keyValue)
      setLoading(false)
      return setNextDataLocations(locationsTree)
    }
  }, [isGetDataHook, isFinish, locations])

  useEffect(() => {
    setLoading(true)
    if (!isGetDataHook && dataLocations !== null && dataLocations.length > 0) {
      if (isIndia && fromLevel !== 2) {
        const nextData = dataLocations?.reduce((prev, curr) => {
          return isArray(curr?.children) && curr?.children?.length > 0
            ? [...prev, ...curr?.children]
            : prev
        }, [])

        const locationsTree = mapData(nextData, fromLevel, toLevel, keyValue)
        setLoading(false)
        return setNextDataLocations(locationsTree)
      }
      const locationsTree = mapData(dataLocations, fromLevel, toLevel, keyValue)
      setLoading(false)
      return setNextDataLocations(locationsTree)
    }
  }, [dataLocations, isGetDataHook])

  const options = useMemo(
    () => (customDataSelect?.length > 0 ? customDataSelect : nextDataLocations),
    [customDataSelect, nextDataLocations]
  )

  if (isOneLevel) {
    return (
      <Select
        allowClear
        showSearch
        value={value}
        onChange={onChange}
        filterOption={(input, option: any) =>
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
        showArrow={false}
        className="w-full"
        placeholder={placeholder}
        mode={multiple ? 'multiple' : undefined}
        maxTagCount="responsive"
      >
        {nextDataLocations?.length > 0 &&
          nextDataLocations?.map((p) => (
            <Select.Option key={p.id} value={p[keyValue]}>
              {p.name}
            </Select.Option>
          ))}
      </Select>
    )
  }
  return (
    <>
      <Cascader
        loading={loading}
        options={options}
        showSearch
        placeholder={placeholder}
        showCheckedStrategy={SHOW_CHILD}
        onChange={onChange}
        value={value}
        className="w-full"
        changeOnSelect
        multiple={multiple}
        expandTrigger="hover"
        size={size}
        disabled={disabled}
        displayRender={(value) => {
          const isRenderSingleLevel = () => {
            const length = value.length
            if (length === 1) {
              return value[0]
            } else {
              let result = ''
              for (let i = 0; i < length; i++) {
                result =
                  result + (i !== length - 1 ? value[i] + '/ ' : value[i])
              }
              return result
            }
          }
          return isRenderSingle || multiple ? (
            <Tooltip
              placement="topLeft"
              title={
                showAreaName
                  ? value[toLevel - fromLevel]
                  : toLevel - fromLevel === 0
                  ? null
                  : value[0] + '/ ' + value[1]
              }
            >
              <div>
                {showAreaName
                  ? value[0] + '/ ' + value[1]
                  : value[toLevel - fromLevel]}
              </div>
            </Tooltip>
          ) : (
            <div>{isRenderSingleLevel()}</div>
          )
        }}
        maxTagCount={maxTagCount || undefined}
      />
    </>
  )
}

export default React.memo(LocationSelect)
