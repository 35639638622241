import dayjs from 'dayjs'
import { Picklist } from 'interfaces/picklist'

import {
  ORDER_ITEM_STATUS_DELIVERED,
  ORDER_ITEM_STATUS_DELIVERY_FAILED,
  PAYMENT_METHOD_BANK_TRANSFER,
  PAYMENT_METHOD_COD,
  PAYMENT_METHOD_CREDIT,
} from './order'

export const PICKLIST_STATUS_CREATED = 'created'
export const PICKLIST_STATUS_CHECKING = 'checking'
export const PICKLIST_STATUS_PICKING = 'picking'
export const PICKLIST_STATUS_COMPLETED = 'completed'
export const PICKLIST_STATUS_INVOICED = 'invoiced'
export const PICKLIST_STATUS_CANCELLED = 'cancelled'
export const PICKLIST_STATUS_IRN_GENERATING = 'irn_generating'
export const PICKLIST_STATUS_IRN_GENERATED = 'irn_generated'

export const PICKLIST_ITEM_STATUS_CREATED = 'created'
export const PICKLIST_ITEM_STATUS_CHECKING = 'checking'
export const PICKLIST_ITEM_STATUS_PICKING = 'picking'
export const PICKLIST_ITEM_STATUS_APPROVED = 'approved'
export const PICKLIST_ITEM_STATUS_REJECTED = 'rejected'
export const CGST = 'cgstPercent'
export const SGST = 'sgstPercent'
export const CESS = 'cessPercent'
export const IGST = 'igstPercent'

export const E_BILL_INFO_STATUS_NOT_SENT = 'not_sent'
export const E_BILL_INFO_STATUS_SUCCESS = 'success'
export const E_BILL_INFO_STATUS_FAILED = 'failed'

export const INVOICE_UPDATED_YES = 'invoice_updated_yes'
export const INVOICE_UPDATED_NO = 'invoice_updated_no'

export const INBOUND_STATUS = {
  CREATED: 'created',
  DISTRIBUTOR_APPROVED: 'distributor_approved',
  FINANCE_APPROVED: 'finance_approved',
  REJECTED: 'rejected',
  BGM_CONFIRMED: 'bgm_confirmed',
}

export const TAX_RECEIPT_MAPPING = {
  [CESS]: 'cess',
  [CGST]: 'cgst',
  [SGST]: 'sgst',
  [IGST]: 'igst',
}

export const HAS_INVOICE_SELECT = {
  [INVOICE_UPDATED_YES]: 'Yes',
  [INVOICE_UPDATED_NO]: 'No',
}

export const PICKLIST_STATUS_COMPLETED_AND_INVOICED_GENERATE_IRN = [
  PICKLIST_STATUS_COMPLETED,
  PICKLIST_STATUS_INVOICED,
]

export const PICKLIST_STATUS_TRANSITION = {
  [PICKLIST_STATUS_CREATED]: [
    PICKLIST_STATUS_PICKING,
    PICKLIST_STATUS_CANCELLED,
  ],
  [PICKLIST_STATUS_PICKING]: [
    PICKLIST_STATUS_COMPLETED,
    PICKLIST_STATUS_CANCELLED,
  ],
}
export const PICKLIST_ITEM_STATUS_TRANSITION = {
  [PICKLIST_ITEM_STATUS_CREATED]: [
    PICKLIST_ITEM_STATUS_APPROVED,
    PICKLIST_ITEM_STATUS_REJECTED,
  ],
}

export const PICKLIST_STATUS_COLOR_MAPPING = {
  [PICKLIST_STATUS_CREATED]: 'blue',
  [PICKLIST_STATUS_PICKING]: 'orange',
  [PICKLIST_STATUS_IRN_GENERATED]: 'orange',
  [PICKLIST_STATUS_COMPLETED]: 'success',
  [PICKLIST_STATUS_CANCELLED]: 'red',
  [PICKLIST_STATUS_INVOICED]: 'default',
}

export const PICKLIST_E_BILL_INFO_STATUS_COLOR_MAPPING = {
  [E_BILL_INFO_STATUS_SUCCESS]: 'green',
  [E_BILL_INFO_STATUS_NOT_SENT]: 'orange',
  [E_BILL_INFO_STATUS_FAILED]: 'red',
}

export const PICKLIST_ITEM_STATUS_COLOR_MAPPING = {
  [PICKLIST_ITEM_STATUS_CREATED]: 'blue',
  [PICKLIST_ITEM_STATUS_APPROVED]: 'success',
  [PICKLIST_ITEM_STATUS_REJECTED]: 'error',
}
export const PICKLIST_STATUS_SELECT_INDIA = {
  [PICKLIST_STATUS_CREATED]: 'CREATED',
  [PICKLIST_STATUS_CHECKING]: 'CHECKING',
  [PICKLIST_STATUS_IRN_GENERATING]: 'IRN GENERATING',
  [PICKLIST_STATUS_IRN_GENERATED]: 'IRN GENERATED',
  [PICKLIST_STATUS_PICKING]: 'PICKING',
  [PICKLIST_STATUS_COMPLETED]: 'COMPLETED',
  [PICKLIST_STATUS_CANCELLED]: 'CANCELLED',
  [PICKLIST_STATUS_INVOICED]: 'INVOICED',
}

export const PICKLIST_STATUS_SELECT_FILTER_INDIA = {
  [PICKLIST_STATUS_CREATED]: 'CREATED',
  [PICKLIST_STATUS_CHECKING]: 'CHECKING',
  [PICKLIST_STATUS_PICKING]: 'PICKING',
  [PICKLIST_STATUS_COMPLETED]: 'COMPLETED',
  [PICKLIST_STATUS_CANCELLED]: 'CANCELLED',
  [PICKLIST_STATUS_INVOICED]: 'INVOICED',
}

export const PICKLIST_E_BILL_INFO_STATUS = {
  [E_BILL_INFO_STATUS_SUCCESS]: 'DONE',
  [E_BILL_INFO_STATUS_NOT_SENT]: 'NOT YET',
  [E_BILL_INFO_STATUS_FAILED]: 'FAILED',
}

export const PICKLIST_STATUS = [
  PICKLIST_STATUS_CREATED,
  PICKLIST_STATUS_CHECKING,
  PICKLIST_STATUS_PICKING,
  PICKLIST_STATUS_COMPLETED,
  PICKLIST_STATUS_CANCELLED,
]
export const PICKLIST_STATUS_TRANSLATE_MAPPING = {
  [PICKLIST_STATUS_CREATED]: 'Đơn mới',
  [PICKLIST_STATUS_CHECKING]: 'Đang in đơn lẻ',
  [PICKLIST_STATUS_PICKING]: 'Đang in đơn tổng',
  [PICKLIST_STATUS_COMPLETED]: 'Hoàn thành',
  [PICKLIST_STATUS_CANCELLED]: 'Đơn đã hủy',
}

export const PICKLIST_ORDER_ITEM_STATUS_TRANSLATE_MAPPING = {
  [ORDER_ITEM_STATUS_DELIVERED]: 'Hoàn thành',
  [ORDER_ITEM_STATUS_DELIVERY_FAILED]: 'Giao thất bại',
}

export type ExportPicklistType = 'csv' | 'xlsx' | 'xlsx_complete'

export const EXPORT_PICKLIST_CSV = 'csv'
export const EXPORT_PICKLIST_XLSX = 'xlsx'
export const EXPORT_PICKLIST_XLSX_COMPLETE = 'xlsx_complete'

export const EXPORT_PICKLIST_TYPES = {
  [EXPORT_PICKLIST_CSV]: 'Export to CSV',
  [EXPORT_PICKLIST_XLSX]: 'Export to MS Excel',
  [EXPORT_PICKLIST_XLSX_COMPLETE]: 'Export to MS Excel and complete',
}

const roundUpto500 = (amount: number) => {
  const roundedAmount = Math.round(amount)
  // redundancy
  const r = roundedAmount % 1000
  if (r === 0) {
    return roundedAmount
  }
  const roundedValue = r >= 500 ? 1000 : 500
  return roundedAmount - r + roundedValue
}

export const buildPicklistOrderExportData = (picklists: Picklist[]) => {
  const data = picklists.reduce((prev, picklist) => {
    const { order, items, warehouse, misaInvoiceUrl } = picklist
    const {
      createdAt,
      tradeOrderCreatedAt,
      tradeOrderNumber,
      agent,
      receiver,
      isNewCustomer,
      remark,
      shippingFee,
    } = order || {}
    const orderCreatedDate = tradeOrderCreatedAt
      ? dayjs(tradeOrderCreatedAt).format('DD-MM-YYYY HH:mm:ss')
      : ''

    const baseObj = {
      'Ngày Order': createdAt ? dayjs(createdAt).format('DD-MM-YYYY') : '',
      'Order No.': tradeOrderNumber,
      'Đường dẫn hóa đơn điện tử': misaInvoiceUrl || '',
      NVBH: agent?.code,
      'Tên NVBH': agent?.name,
      'SĐT NVBH': agent?.phone,
      'Mã KH': receiver?.code,
      'Tên KH': receiver?.name,
      'Mã số thuế': receiver?.taxNumber,
      'Địa chỉ trên giấy phép': receiver?.addressOnLicense,
      Email: receiver?.email,
    }

    const totalAdjustQty = items?.reduce(
      (prev, curr) => prev + curr?.orderItem?.adjustQuantity,
      0
    )

    const nextItems = items.map(
      ({ orderItem, orderItemInventoryMovements }) => {
        const {
          sku,
          name,
          unitPrice,
          product,
          promotionDiscount,
          productType,
          vigoCommission,
          salesCommissionAmount,
          cogs,
          status,
          distributorPriceSubsidy,
          saSuccessFee,
          adjustQuantity,
          agentPriceSubsidy,
          quantity,
          purchaseQuantity,
        } = orderItem || {}
        const ratioQty =
          purchaseQuantity !== 0 ? adjustQuantity / purchaseQuantity : 0
        // average SaSuccessFee
        const avgSaSuccessFee =
          adjustQuantity !== 0 && purchaseQuantity > 0
            ? saSuccessFee / purchaseQuantity
            : 0
        //Giá bán
        const price = unitPrice + avgSaSuccessFee
        // Khuyến mãi
        const promoByAdjustQty = promotionDiscount * ratioQty
        // Thành tiền
        const subtotal =
          adjustQuantity !== 0
            ? Math.round(
                adjustQuantity * (avgSaSuccessFee + unitPrice) -
                  promoByAdjustQty
              )
            : 0
        //tổng tiền hổ trợ
        const agentSubsidyByAdjustQty = agentPriceSubsidy * ratioQty

        const disSubsidyByAdjustQty = distributorPriceSubsidy * ratioQty

        const totalSubsidy =
          agentSubsidyByAdjustQty + disSubsidyByAdjustQty || 0

        //Phí giao hàng
        const shippingFeeItem = (
          shippingFee *
          (adjustQuantity / (totalAdjustQty || 1))
        ).toFixed(2)
        // Giá sau hỗ trợ từ NVBH
        const subtotalIncludingFee =
          adjustQuantity !== 0
            ? Math.round(
                Math.round(subtotal - totalSubsidy) +
                  parseFloat(shippingFeeItem)
              )
            : 0
        const roundedSubtotalIncludingFee = roundUpto500(subtotalIncludingFee)
        // Hỗ trợ từ NVBH
        const supportFee = Math.max(subtotal - subtotalIncludingFee, 0) // prevent negative value
        // Hỗ trợ từ NPP
        const supportFeeDistributor = Math.round(distributorPriceSubsidy || 0)
        const batchNumber = orderItemInventoryMovements
          ?.map((item) => item?.physicalBatchNumber || '')
          .toString()
        const expirationDate = orderItemInventoryMovements
          ?.map((item) => {
            return item?.additionalInfo?.expirationDate
              ? dayjs(item?.additionalInfo?.expirationDate).format('DD-MM-YYYY')
              : ''
          })
          .toString()
        return {
          ...baseObj,
          'Mã SP': sku,
          'Tên SP': name,
          'Mã Dược Quốc Gia':
            orderItemInventoryMovements?.[0]?.additionalInfo?.nationalDrugCode,
          'Số Lô': batchNumber,
          'Hạn dùng': expirationDate,
          ĐVT: product?.unit, // eslint-disable-line
          'Giá Bán': price,
          'Số Lượng': quantity,
          'Số Lượng Đã Giao': adjustQuantity,
          'Thành Tiền': subtotal,
          'Hỗ Trợ Từ NVBH': supportFee,
          'Giá Sau Hỗ Trợ Từ NVBH': subtotalIncludingFee,
          'Giá Sau Hỗ Trợ Từ NVBH Làm Tròn': roundedSubtotalIncludingFee,
          'Trợ giá từ Nhà phân phối': supportFeeDistributor,
          'Trợ giá tự động':
            orderItem?.distributorSubsidy?.autoSubsidyAmount || 0,
          'Trợ giá thủ công':
            orderItem?.distributorSubsidy?.manualSubsidyAmount || 0,
          'Trợ giá đặc biệt':
            orderItem?.distributorSubsidy?.specialSubsidyAmount || 0,
          'Khuyến mãi giảm giá': promoByAdjustQty,
          'Thời gian tạo': orderCreatedDate,
          'Ghi chú': remark,
          'SĐT KH': receiver?.phone,
          'Địa chỉ giao hàng': receiver?.address?.detail,
          'Quy cách': product?.pack,
          'Khách hàng mới': isNewCustomer ? 'Yes' : 'No',
          Kho: warehouse?.code,
          'Kho tổng': order?.hubWarehouse?.code,
          'Trạng thái': PICKLIST_STATUS_TRANSLATE_MAPPING[picklist.status],
          'Trạng thái giao hàng':
            status === ORDER_ITEM_STATUS_DELIVERED ||
            status === ORDER_ITEM_STATUS_DELIVERY_FAILED
              ? PICKLIST_ORDER_ITEM_STATUS_TRANSLATE_MAPPING[status]
              : 'Đang xử lý',
          'Loại sản phẩm': productType,
          'Đơn giá vốn': cogs,
          'Đơn giá Listing': vigoCommission,
          'Phí dịch vụ bán hàng thành công': saSuccessFee
            ? saSuccessFee
            : salesCommissionAmount,
          'Phí giao hàng': parseFloat(shippingFeeItem),
        }
      }
    )

    return [...prev, ...nextItems]
  }, [])

  return data
}

export const MoneyIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125ZM7.74531 6.6375L7.34844 6.54531V4.44688C7.94219 4.52812 8.30937 4.9 8.37187 5.35625C8.37969 5.41875 8.43281 5.46406 8.49531 5.46406H9.19687C9.27031 5.46406 9.32812 5.4 9.32187 5.32656C9.22656 4.35313 8.425 3.72813 7.35469 3.62031V3.10938C7.35469 3.04062 7.29844 2.98438 7.22969 2.98438H6.79063C6.72188 2.98438 6.66563 3.04062 6.66563 3.10938V3.625C5.55938 3.73281 4.69375 4.34375 4.69375 5.48438C4.69375 6.54063 5.47188 7.05 6.28906 7.24531L6.675 7.34375V9.57344C5.98438 9.48125 5.59688 9.1125 5.51719 8.61563C5.50781 8.55625 5.45469 8.5125 5.39375 8.5125H4.67188C4.59844 8.5125 4.54063 8.575 4.54688 8.64844C4.61719 9.50781 5.26875 10.2984 6.65938 10.4V10.8906C6.65938 10.9594 6.71563 11.0156 6.78438 11.0156H7.22812C7.29687 11.0156 7.35313 10.9594 7.35313 10.8891L7.35 10.3938C8.57344 10.2859 9.44844 9.63125 9.44844 8.45625C9.44688 7.37187 8.75781 6.8875 7.74531 6.6375ZM6.67344 6.38437C6.58594 6.35938 6.5125 6.33594 6.43906 6.30625C5.91094 6.11563 5.66563 5.80781 5.66563 5.41094C5.66563 4.84375 6.09531 4.52031 6.67344 4.44688V6.38437ZM7.34844 9.57812V7.48906C7.39687 7.50313 7.44062 7.51406 7.48594 7.52344C8.225 7.74844 8.47344 8.06094 8.47344 8.54062C8.47344 9.15156 8.01406 9.51875 7.34844 9.57812Z"
      fill="#73D13D"
    />
  </svg>
)

export const BankIcon = (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9685 5.34297C13.4513 5.34297 13.6528 4.72266 13.2606 4.43672L7.29346 0.0960936C7.20824 0.033659 7.10535 0 6.99971 0C6.89407 0 6.79117 0.033659 6.70596 0.0960936L0.738768 4.43672C0.34658 4.72109 0.548143 5.34297 1.03252 5.34297H1.99971V11.1867H0.874705C0.805955 11.1867 0.749705 11.243 0.749705 11.3117V12.1242C0.749705 12.193 0.805955 12.2492 0.874705 12.2492H13.1247C13.1935 12.2492 13.2497 12.193 13.2497 12.1242V11.3117C13.2497 11.243 13.1935 11.1867 13.1247 11.1867H11.9997V5.34297H12.9685ZM6.99971 1.19766L11.2356 4.27891H2.76377L6.99971 1.19766ZM3.12471 5.34297H4.95283V11.1867H3.12471V5.34297ZM6.07783 5.34297H7.90596V11.1867H6.07783V5.34297ZM10.8747 11.1867H9.03096V5.34297H10.8747V11.1867Z"
      fill="#597EF7"
    />
  </svg>
)

export const DocumentIcon = (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.375 1.29163L9.5 0.416626L8.625 1.29163L7.75 0.416626L6.875 1.29163L6 0.416626L5.125 1.29163L4.25 0.416626L3.375 1.29163L2.5 0.416626V8.58329H0.75V10.3333C0.75 11.3016 1.53167 12.0833 2.5 12.0833H9.5C10.4683 12.0833 11.25 11.3016 11.25 10.3333V0.416626L10.375 1.29163ZM7.75 10.9166H2.5C2.17917 10.9166 1.91667 10.6541 1.91667 10.3333V9.74996H7.75V10.9166ZM10.0833 10.3333C10.0833 10.6541 9.82083 10.9166 9.5 10.9166C9.17917 10.9166 8.91667 10.6541 8.91667 10.3333V8.58329H3.66667V2.16663H10.0833V10.3333Z"
      fill="#9254DE"
    />
    <path d="M7.75 3.33329H4.25V4.49996H7.75V3.33329Z" fill="#9254DE" />
    <path d="M9.5 3.33329H8.33333V4.49996H9.5V3.33329Z" fill="#9254DE" />
    <path d="M7.75 5.08329H4.25V6.24996H7.75V5.08329Z" fill="#9254DE" />
    <path d="M9.5 5.08329H8.33333V6.24996H9.5V5.08329Z" fill="#9254DE" />
  </svg>
)

export const PAYMENT_METHOD_ICON_MAPPING = {
  [PAYMENT_METHOD_COD]: MoneyIcon,
  [PAYMENT_METHOD_BANK_TRANSFER]: BankIcon,
  [PAYMENT_METHOD_CREDIT]: DocumentIcon,
}
