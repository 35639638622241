import styled from '@emotion/styled'
import { Menu } from 'antd'
import ACL, { isAdmin, isDistributor } from 'common/config/acl'
import { useAppContext } from 'contexts/AppContext'
import { omit } from 'lodash'
import get from 'lodash/get'
import Link from 'next/link'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import React, { memo, useMemo } from 'react'
import { isIndia } from 'utils/commonUtils'
import { COMMON } from 'utils/localUtils'
import {
  LEFT_NAVIGATION,
  ROUTE_TRACKING_MAPPING,
  tracking,
  VIEW_LIST,
} from 'utils/tracking'
import { ACCESS_DENIED_MIS_SCREEN } from 'utils/user'

import { INavigation, mainNavigationTree } from './navigationTree'

const { SubMenu } = Menu
export const SubMenuCus = styled(SubMenu)`
  .ant-menu-title-content {
    line-height: 1.25rem;
  }
`

const handleTracking = (route: string) => {
  const routeTracking = Object.keys(ROUTE_TRACKING_MAPPING).includes(route)
  //currently only tracking user clicked menu subsidy management
  if (routeTracking && !isIndia) {
    const properties = {
      source_screen: LEFT_NAVIGATION,
    }
    return tracking(ROUTE_TRACKING_MAPPING[route], properties, VIEW_LIST)
  }
}
export const renderMenuFn = (route: INavigation, translate) => {
  if (route.display) {
    const filtered = (route.children || []).filter((i) => i.display)
    const tempRoute = { ...route, children: filtered }
    if (tempRoute.children.length > 0) {
      return renderSubMenu(tempRoute, translate)
    }
    return renderMenuItem(tempRoute, translate)
  }
  return null
}

export const renderMenuItem = (route: INavigation, translate) => {
  return (
    <Menu.Item
      key={route.key}
      icon={route.icon}
      className="cursor-pointer whitespace-normal leading-5"
      onClick={() => handleTracking(route.href)}
    >
      <Link href={route.href}>
        <a>{translate(route.name)}</a>
      </Link>
    </Menu.Item>
  )
}

export const renderSubMenu = (route: INavigation, translate) => (
  <SubMenuCus
    key={route.key}
    icon={route.icon}
    className="whitespace-normal leading-5"
    title={translate(route.name)}
  >
    {route.children?.map((item) => renderMenuFn(item, translate))}
  </SubMenuCus>
)

interface NavigationMenuProps {
  mode?: string
  isMobile?: boolean
}

const buildAccessibleRoutesIndia = (
  routes: INavigation[],
  aclRoutes: string[],
  accessDenied: string[]
) => {
  return routes.reduce((prev, currentRoute) => {
    const filtered = currentRoute.children?.filter(
      (r) =>
        r.display && aclRoutes.includes(r.key) && !accessDenied.includes(r.key)
    )
    if (!accessDenied.includes(currentRoute.key) && filtered?.length > 0) {
      return [...prev, { ...currentRoute, children: filtered }]
    }
    return [...prev]
  }, [])
}

const buildAccessibleRoutes = (routes: INavigation[], aclRoutes: string[]) => {
  return routes.reduce((prev, currentRoute) => {
    if (currentRoute?.children?.length > 0) {
      const filtered = currentRoute.children?.filter((r) => {
        return aclRoutes.includes(r.key)
      })
      if (filtered.length > 0) {
        return [...prev, { ...currentRoute, children: filtered }]
      }
    }
    return [...prev]
  }, [])
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  mode = 'vertical',
  isMobile,
}) => {
  const { data: session } = useSession()
  const translate = useTranslations(COMMON)
  let accessibleRoutes = mainNavigationTree?.children
  const userRoles = get(session, 'user.roles', []) as string[]
  const distributorRoles = get(session, 'user.distributorRoles', []) as string[]
  const {
    state: { distributor },
  } = useAppContext()

  const isRoleDistributor = isDistributor(userRoles)

  const isNotOwnerDistributor = useMemo(
    () => isRoleDistributor && !distributor,
    [isRoleDistributor, distributor]
  )

  if (!isAdmin(userRoles)) {
    const accessDenied = distributorRoles?.length
      ? omit(ACCESS_DENIED_MIS_SCREEN, distributorRoles)
      : ACCESS_DENIED_MIS_SCREEN
    const mappingAccessDenied = Object.entries(accessDenied).map(
      (scr) => scr[1]
    )

    const aclRoutes = userRoles?.reduce((prev, role) => {
      const tmpRoles = ACL[role] || []
      return [...prev, ...tmpRoles]
    }, [])

    accessibleRoutes =
      isIndia && isNotOwnerDistributor
        ? buildAccessibleRoutesIndia(
            accessibleRoutes,
            aclRoutes,
            mappingAccessDenied
          )
        : buildAccessibleRoutes(accessibleRoutes, aclRoutes)
  }

  return (
    <Menu
      theme={mode === 'vertical' ? 'light' : 'dark'}
      mode={
        mode === 'vertical' || (mode === 'horizontal' && isMobile)
          ? 'inline'
          : 'horizontal'
      }
    >
      <Menu.Item key={mainNavigationTree.key} icon={mainNavigationTree.icon}>
        <Link href={mainNavigationTree.href}>
          <a>{translate(mainNavigationTree.name) || mainNavigationTree.name}</a>
        </Link>
      </Menu.Item>

      {accessibleRoutes.map((item) => renderMenuFn(item, translate))}
    </Menu>
  )
}

export default memo(NavigationMenu)
