export const CONSIGNMENT_CASH_EN = {
  title: 'Consignment Cash Reconciliation',
  cashNumber: 'Cash Reconciliation Number',
  fromToDate: 'Reconciliation From Date - To Date',
  reconciliationDate: 'Reconciliation Date',
  totalVatPrice: 'Total VAT Price Amount',
  created: 'CREATED',
  vigo_confirmed: 'VIGO CONFIRMED',
  need_adjustment: 'NEED ADJUSTMENT',
  supplier_confirmed: 'SUPPLIER CONFIRMED',
  cName: 'Company Name',
  vigoConfirm: 'Vigo Confirm',
  requestAdjust: 'Request Adjust',
  reasonRequestAdjust: 'Reason Request Adjust',
  suggestAdjust: 'Suggest Adjust',
  supplierConfirm: 'Supplier Confirm',
}
