export const AGENT_VI = {
  title: 'Quản lý nhân viên bán hàng',
  agent: 'Nhân viên bán hàng ',
  all: 'TẤT CẢ',
  inactive: 'KHÔNG HOẠT ĐỘNG',
  active: 'HOẠT ĐỘNG',
  agentType: 'Hình thức',
  inventoryValue: 'Thông tin giá trị tồn kho',
  collectionHistory: 'Lịch sử thu tiền',
  saleSupervisor: 'Giám sát bán hàng',
  agentCode: 'Mã NVBH',
  saleSupervisorCode: 'Email giám sát bán hàng',
  saleSupervisorName: 'Tên giám sát bán hàng',
  createNewAgent: 'Tạo nhân viên bán hàng mới',
  updateAgent: 'Cập nhật thông tin nhân viên bán hàng',
  linkAgent: 'Liên kết thông tin nhân viên bán hàng',
  unlinkAgent: 'Hủy liên kết thông tin nhân viên bán hàng',
  requiredFirstName: 'Vui lòng nhập tên của NVBH',
  requiredLastName: 'Vui lòng nhập họ của NVBH',
  requiredPhone: 'Vui lòng nhập số điện thoại',
  requiredSaleSupervisor: 'Vui lòng chọn một giám sát bán hàng',
  updateAgentSuccess:
    'Yêu cầu cập nhật NVBH đã được tạo, vui lòng chờ bộ phận Admin kiểm duyệt',
  createAgentSuccess:
    'Yêu cầu tạo NVBH đã được tạo, vui lòng chờ bộ phận Admin kiểm duyệt',
  linkAgentSuccess: 'Liên kết NVBH thành công',
  agentPhone: 'Số điện thoại NVBH',
  agentEmail: 'Email NVBH',
  'in-active': 'KHÔNG HOẠT ĐỘNG',
  collectionHistoryOf: 'Lịch sử thu tiền của',
  outletDetail: 'Chi tiết cửa hàng',
  agentDetail: 'Chi tiết nhân viên bán hàng',
  totalInvAllocated: 'Tổng giá trị tồn kho được phân bổ',
  inventoryValueAvailable: 'Giá trị tồn kho khả dụng',
  outstandingInvValue: 'Giá trị tiền hàng đang giữ',
  requesting: 'ĐANG YÊU CẦU',
  temp: 'TẠM THỜI',
  deleted: 'ĐÃ XÓA',
  disable: 'VÔ HIỆU HÓA',
  rejected: 'ĐÃ TỪ CHỐI',
  createSuccess:
    'Yêu cầu tạo NVBH đã được tạo, vui lòng chờ bộ phận Admin kiểm duyệt',
  updateSuccess:
    'Yêu cầu cập nhật NVBH đã được tạo, vui lòng chờ bộ phận Admin kiểm duyệt',
  unlinkMessage: 'Bạn xác nhận muốn hủy liên kết với  NVBH này?',
}
