export const PURCHASE_ORDER_REPORT_VI = {
  title: 'Báo cáo tổng giá trị mua vào',
  totalUnitPriceVat: 'Thành tiền b/g VAT',
  unitPriceVat: 'Đơn giá (VAT)',
  weekOfMonth: 'Tuần trong tháng',
  vpoCreatedDate: 'Ngày tạo VPO',
  fromDate: 'Từ ngày',
  toDate: 'Đến ngày',
  'week-1': 'Tuần 1',
  'week-2': 'Tuần 2',
  'week-3': 'Tuần 3',
  'week-4': 'Tuần 4',
  productNameOrSku: 'Nhập tên sản phẩm hoặc mã SKU để tìm',
}
