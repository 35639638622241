export const CONSIGNMENT_CASH_VI = {
  title: 'Đối soát tiền hàng ký gửi',
  cashNumber: 'Mã đối soát tiền hàng',
  fromToDate: 'Đối soát từ ngày - đến ngày',
  reconciliationDate: 'Ngày đối soát',
  totalVatPrice: 'Tổng tiền VAT',
  created: 'MỚI',
  vigo_confirmed: 'VIGO ĐÃ XÁC NHẬN',
  need_adjustment: 'CẦN ĐIỀU CHỈNH',
  supplier_confirmed: 'NHÀ PHÂN PHỐI ĐÃ XÁC NHẬN',
  cName: 'Tên Công Ty',
  vigoConfirm: 'Vigo xác nhận',
  requestAdjust: 'Yêu cầu điều chỉnh',
  reasonRequestAdjust: 'Lý do yêu cầu điều chỉnh',
  suggestAdjust: 'Gợi ý điều chỉnh',
  supplierConfirm: 'Nhà cung cấp xác nhận',
}
