export const PURCHASE_ORDER_REPORT_EN = {
  title: 'Total Purchase Value Report',
  totalUnitPriceVat: 'Total Unit Price(VAT)',
  unitPriceVat: 'Unit Price(VAT)',
  weekOfMonth: 'Week of Month',
  vpoCreatedDate: 'VPO Created Date',
  fromDate: 'From Date',
  toDate: 'To Date',
  'week-1': 'Week 1',
  'week-2': 'Week 2',
  'week-3': 'Week 3',
  'week-4': 'Week 4',
  productNameOrSku: 'Input Product Name or SKU to search',
}
