import { isIndia } from 'utils/commonUtils'

export const TASK_EN = {
  tasksList: 'Tasks list',
  upload: 'Upload',
  refresh: 'Refresh',
  taskUpload: 'Task Upload',
  documentType: 'Document Type',
  selectDocumentType: 'Select Document Type',
  downloadTemplate: 'Download Template',
  fileUpload: 'File Upload',
  chooseFile: 'Choose File',
  submit: 'Submit',
  cancel: 'Cancel',
  inboundUpload: 'Inbound Upload',
  packageStatusUpload: 'Package Status Upload',
  outletSetCreditUpload: 'Outlet Set Credit Upload',
  outletCreditCollectionUpload: 'Outlet Credit Collection Upload',
  packageCollectionMethodUpload: 'Package Collection Method Upload',
  inventoryOverrideUpload: 'Inventory Override Upload',
  'pre-order-export': isIndia ? 'Trade Order Export' : 'Pre Order Export',
  'outlet-set-credit-limit-upload': 'Outlet Set Credit Limit Upload',
  'outlet-need-to-set-credit-export': 'Outlet Need To Set Credit Export',
  'outlet-credit-report-export': 'Outlet Credit Export',
  'outlet-credit-transaction-export': 'Outlet Credit Transaction Export',
  'outlet-outstanding-transaction-export':
    'Outlet Outstanding Transaction Export',
  'outlet-credit-outstanding-transaction-export':
    'Outlet Outstanding Transaction Export',
  'inventory-recommendation-config-upload':
    'Inventory Recommendation Config Upload',
  'product-trading-export': 'Product Trading Export',
  'product-trading-upload': 'Product Trading By Listing Create',
  'inventory-override-awaiting-for-sale-upload':
    'Upload Override Awaiting For Sale',
  'auto-procurement-config-upload': 'Automatic Procurement Config Upload',
  'pre-order-config-upload': 'Pre Order Config Upload',
  'purchase-order-massive-create-po-upload': 'Purchase Order',
  'product-tax-upload': 'Product Tax Upload',
  'bi-supplier-sku-mapping-upload': 'Supplier Invoice Product Mapping',
  'goods-not-focus-config-upload': 'Goods Not Focus',
  'subsidy-rule-upload': 'Subsidy Rule Upload',
  'product-listing-upload': 'Product Listing',
  'distributor-contract-fee-upload': 'Distributor Contract Fee',
  'cm-target-config-upload': 'Config CM3 Target',
  'purchase-request-upload': 'Purchase Request Upload',
  manualSubsidy: 'Manual Subsidy',
  buyinExport: 'Buyin Export',
  selloutExport: 'SelloutExport',
  packageExport: 'Package Export',
  fileName: 'File Name',
  status: 'Status',
  completed: 'Completed',
  in_progress: 'In Progress',
  'in-progress': 'In Progress',
  failed: 'Failed',
  type: 'Type',
  inventoryExport: 'inventory-export',
  createdDate: 'Created Date',
  startDate: 'Start Date',
  endDate: 'End Date',
  search: 'Search',
  reset: 'Reset',
  updatedAt: 'Updated At',
  action: 'Action',
  createdAt: 'Created At',
  downloadOriginalFile: 'Download Original File',
  downloadErrorFile: 'Download Error Files',
  inventoryRecommend: 'Config recommend source of goods',
  bulkCreateProductTradingByListing: 'Bulk Create Product Trading By Listing',
  productPreOrder: 'Product Pre-Order',
  massiveCreatePurchaseOrder: 'Purchase Order',
  taxManagement: 'Tax Management',
  supplierInvoiceProductMapping: 'Supplier Invoice Product Mapping',
  uploadSuccess: 'Import file successful!',
  uploadFailed: 'Import file failed!',
  bulkCreateProductListing: 'Bulk Create Product Listing',
  contractFeeManager: 'Distributor Contract Fee',
  cm3Target: 'Config CM3 Target',
  bulkCreatePurchaseRequest: 'Bulk Create',
  requiredUpload: 'Please upload file',
  massImportDelivery: 'Mass Import Delivery Hub',
}
