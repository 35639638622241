export const AGENT_COLLECTION_EN = {
  title: 'Agent Confirm Collection',
  agent: 'Agent',
  all: 'ALL',
  waiting_collect: 'WAITING COLLECT',
  partial_collected: 'PARTIAL COLLECT',
  collected: 'COLLECTED',
  rejected: 'REJECTED',
  outstandingAndCollected: 'Outstanding Amt/ Amt Collected',
  buttonCollected: 'Collected',
  agentType: 'Agent Type',
  inventoryValue: 'Inventory Value Information',
  collectionHistory: 'Collection History',
  saleSupervisor: 'Sale Supervisor',
  agentCode: 'Agent Code',
  saleSupervisorCode: 'Sale Supervisor Email',
  saleSupervisorName: 'Sale Supervisor Name',
  createNewAgent: 'Create New Agent',
  updateAgent: 'Update Agent Information',
  linkAgent: 'Link Agent Information',
  unlinkAgent: 'Unlink Agent Information',
  requiredFirstName: 'Please input first name of agent',
  requiredLastName: 'Please input last name of agent',
  requiredPhone: 'Please input phone number',
  requiredSaleSupervisor: 'Please choose one sale supervisor',
  updateAgentSuccess: 'Created agent, please wait for admin approve',
  createAgentSuccess: 'Create agent request created',
  linkAgentSuccess: 'Link agent successfully',
  agentPhone: 'Agent Phone',
  agentEmail: 'Agent Email',
  massiveCollect: 'Massive Collect',
  confirmCollect:
    'Are you sure confirm that the full payment has been collected for all selected orders?',
  confirmReject: 'Are you sure you want to reject this?',
  titleConfirmCollectModal: 'Confirm Collection of Outlet',
  collectionInfo: 'Collection Information',
  agentNameCode: 'Agent Name/Code',
  outletNameCode: 'Outlet Name/Code',
  dateTimeCollection: 'Date & Time of Collection',
  outstandingAmt: 'Outstanding Amount',
  collectionAmt: 'Collection Amount',
  collectionMethod: 'Collection Method',
  cash: 'Cash',
  bank_transfer: 'Bank Transfer',
  additionalDetail: 'Additional Details',
  updateFrom: 'Update From',
  viewAdditionalDetail: 'View Additional Details',
}
